#apppage .view {
  background-image: url("./bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 100vh;
}

#apppage h6 {
  line-height: 1.7;
}

@media only screen and (max-width: 400px) {
  #apppage .animated img {
    max-height: 50vh;
    transform: translateX(-50%);
    left: 50%;
    position: absolute;
  }
  #apppage .view {
    min-height: 100vh;
  }
  
}

@media (max-width: 767px) and (orientation: landscape) {
  #apppage .animated img {
    max-height: 50vh;
    transform: translateX(-50%);
    left: 50%;
    position: relative;
  }
}

@media (max-width: 767px) {
  .minTit {
    font-size: 40px;
  }
  .minDes {
    font-size: 12px;
  }
}

@media (max-width: 1000px) and (orientation: landscape) {
  #apppage .view {
    /* min-height: 150vh; */
  }
}