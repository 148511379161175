@media (max-width: 768px) {
    .react-bootstrap-table thead {
      display: none;
    }
  
    .react-bootstrap-table,
    .react-bootstrap-table tbody,
    .react-bootstrap-table tr,
    .react-bootstrap-table td {
      display: block;
      width: 100%;
    }
  
    .react-bootstrap-table tr {
      margin-bottom: 15px;
      border: 2px solid #ddd;
    }

    /* .react-bootstrap-table td div[data-label] {
      display: flex;
    } */
  
    .react-bootstrap-table td div[data-label]:before {
      content: attr(data-label);
      font-weight: bold;
    }
    
  }