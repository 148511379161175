ul.todo-list{
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    margin-bottom: 30px;
  }
  
  ul.todo-list li{
    height: 70px;
    border-bottom: 1px solid #E7E7E7;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  ul.todo-list li .icon{
    width: 60px;
    height: 60px;
    max-width: 60px;
    min-width: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }
  
  ul.todo-list li .icon img{
    max-width: 60px;
    max-height: 60px;
  }
  
  ul.todo-list li .name{
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
  }
  
  ul.todo-list li .name span{
    font-family: 'Montserrat', sans-serif;
    color: #5A5A5A;
    font-size: 14px;
    font-weight: 400;
  }
  
  ul.todo-list li .name span.item-price{
    font-size: 11px;
    color: #A6A6A6;
  }
  
  
  ul.todo-list li .price{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 60px;
    min-width: 90px;
  }
  ul.todo-list li .price h3{
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    color: #9D3872;
    font-size: 24px;
  }
  
  ul.todo-list .remove{
    height: 60px;
    width: 60px;
    min-width: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border:0px;
    background-color: transparent;
  }
  ul.todo-list li .remove i{
    color: #979797;
    font-size: 24px;
  }
  
  button.add-button{
    background-color: #69B614;
    width: 129px;
    height: 37px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    border:0px;
    font-size: 11px;
    cursor: pointer;
    outline: none;
  }

