.upload-box{
    font-size: 14px;
    background: white;
    border-radius: 50px;
    box-shadow: 5 px 5px 10 px black;
    width: 350px;
    outline: none;
  
}
::-webkit-file-upload-button{
    color: white;
    background: #337AB7;
    padding: 12px;
    border: none;
    border-radius: 50px;
    box-shadow: 1px 0 1px #337AB7;
}
