.custom-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    box-sizing: border-box;
  }

  .red-button {
    width: 150px;
    height: 40px;
    background-color: #ff4d4d; /* Color rojo */
    color: #fff; /* Texto blanco */
    border: 1px solid #ff4d4d; /* Borde rojo */
  }
  
  .swal-button--cancel:not([disabled]):hover {
    background-color: #db6d6d;
}