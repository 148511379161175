.wrap {
display: flex;
background: rgb(243, 243, 247);
padding: 1rem 1rem 1rem 1rem;
border-radius: 1rem;
box-shadow: 7px 7px 30px -5px rgba(0,0,0,0.1);
margin-bottom: 2rem;

}
.wrap:hover {
background: linear-gradient(135deg,#b1d0e4 0%,#337AB7 100%);
}
.ico-wrap {
margin: left;

}

.vcenter {
margin: auto;

}

h2 {
margin-top: 0.5rem;
margin-bottom: 0.5rem;
color:#3a2222;

}
